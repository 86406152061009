import classNames from 'classnames';
import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { useEditorLexical } from 'modules/Lexical/hooks/useCallToActionEditor';
import React, { useRef } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Toolbar from 'components/Toolbar/CallToAction';
import UndoPortal from 'components/UndoRedo/UndoPortal';
import * as Constants from 'const';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { useProjectType } from 'hooks/useProjectType';
import { AssetAlignment } from 'models';
import { allowLinkRedirect } from 'utils/allowLinkRedirect';
import * as editorUtils from 'utils/editor';
import * as styleUtils from 'utils/styles';
import editorWrapperCss from '../editor-wrapper.module.scss';
import { useEditorToggle } from '../useEditorToggle';
import { ButtonProvider, ResizeProvider } from './ButtonElement/Context';
import DraftjsButton from './ButtonElement/DraftjsButton';
import LexicalButton from './ButtonElement/LexicalButton';
import ResizableButton from './ButtonElement/ResizableButton';
import { EditorHook } from './editor';
import { useCallToAction } from './hooks';
import { useEditorDraftjs } from './hooks/useEditorDraftjs';
import { useResizeObserver } from './hooks/useResizeObserver';
import { useSizeReset } from './hooks/useSizeReset';
import { useStyles } from './hooks/useStyles';
import { useUndo } from './hooks/useUndo';
import { CallToActionProps } from './models';
import css from './styles.module.scss';
import { wrapEditorSettersWithUndo, wrapUndoMiddleware } from './undo';

export function getCallToActionDOMId(relationId: string): string {
  return `call-to-action-cell-${relationId}`;
}

const CallToAction: React.FunctionComponent<CallToActionProps> = (props) => {
  const {
    cellHeight,
    cellWidth,
    disableCellWidthEditing,
    editMode,
    isIncreasingWidthDisabled,
    layoutId,
    maxCellHeight,
    minCellHeight,
    relation,
    toggleColumnWidth,
    toggleRowHeight,
    toggleAutoFitContent,
    isAutoFitContent,
    saveAppState,
    cancel,
    flatColorsByRelationId,
    images,
  } = props;

  const projectType = useProjectType();
  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const colors = flatColorsByRelationId.get(relationId);

  const editorWrap = useRef<HTMLDivElement>(null);

  const stylesHook = useStyles(
    relation,
    activeLayer,
    { colors, images, defaultAssetBackgroundColor: Constants.DefaultCallToActionBackgroundColor },
    Constants.ProjectsConfig[projectType],
    cellWidth,
  );

  const { styles, css: stylesCSS, getMaxWidth } = stylesHook;

  const sizeResetHook = useSizeReset(props, stylesHook);

  const { isLexicalMode, isBothMode, isDraftjsMode } = useEditorToggle();

  const lexicalHook = useEditorLexical(sizeResetHook, editorWrap);
  const draftjsHook = useEditorDraftjs(props, sizeResetHook, editorWrap);

  const editorHook: EditorHook = isLexicalMode
    ? { ...lexicalHook, getData: draftjsHook.getData }
    : draftjsHook;

  const {
    container,
    link,
    modifiedStylesSetters: stylesSetters,
    toggleLink,
    getHeight,
    getWidth,
    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,
    finishResizing,
    onResize,
    brandProps,
  } = useCallToAction(props, stylesHook, sizeResetHook, editorHook, editorWrap);

  useResizeObserver(editorWrap, styles, stylesSetters, editMode);

  const {
    editorRef,
    editorState,
    setEditorState,
    onEditorChange,
  } = draftjsHook;

  const {
    returnFocusToEditor,
    props: editorProps,
    setters: editorSetters,
  } = editorHook;

  const undoHook = useUndo(
    editMode, saveAppState, cancel,
    draftjsHook, // IN-PROGRESS
    stylesHook,
    {
      isAutoFitContent,
      toggleAutoFitContent,
      link,
      toggleLink,
      cellHeight,
      cellWidth,
      getHeight,
      getWidth,
      toggleRowHeight,
      toggleColumnWidth,
    },
  );

  const handleClick = (event: React.MouseEvent): void => {
    if (allowLinkRedirect(event) && link) {
      window.open(link, '_blank');
    }
  };

  const stylesSettersWithUndo = wrapUndoMiddleware(undoHook.undoStackMiddleware, stylesSetters);
  const editorSettersWithUndo = wrapEditorSettersWithUndo(undoHook.undoStackMiddleware, editorSetters);

  const startResizing = (currentWidth: number, currentHeight: number): void => {
    undoHook.undoStackMiddleware(() => {}, 'width', true)(currentWidth);
    undoHook.undoStackMiddleware(() => {}, 'height', true)(currentHeight);
  };

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, (abbreviationId, abbrreviationNumber) => {
    const newState = editorUtils.selectAbbreviation(editorState, abbreviationId, abbrreviationNumber);
    if (!newState) {
      return false;
    }
    setEditorState(newState);

    return true;
  });

  useCellActionsExecutor(CellAction.APPLY_SELECTION, (
    blockKey: string,
    start: number,
    end: number,
  ) => {
    setEditorState(editorUtils.applySelection(editorState, blockKey, start, end));
  });

  const buttonProps = {
    id: relation.get('id'),
    editorState,
    editorRef,
    lexicalEditorRef: lexicalHook.editorRef,
    lexicalHookOnChange: lexicalHook.onChange,
    cssButton:css.button,
    styles: {
      ...stylesCSS.element,
      ...styleUtils.getButtonSize(getHeight(), getWidth(), editMode),
    },
    editMode,
    editorWrap,
    onEditorChange,
    returnFocusToEditor,
    undoHook,
    brandProps,
  };

  const resizeProps = {
    alignment: styles.alignment.toJS() as AssetAlignment,
    container: container.current ?? undefined,
    startResizing,
    finishResizing,
    onResize,
    width: getResizeComponentWidth(),
    height: getResizeComponentHeight(),
    minHeight: getResizeComponentMinHeight(),
  };

  return (
    <AccessibleDiv
      className={css.CallToAction}
      onClick={handleClick}
      ref={container}
      style={stylesCSS.container}
    >
      <ButtonProvider value={buttonProps}>
        <ResizeProvider value={resizeProps}>
          <div className={classNames({
            [editorWrapperCss.editorModeBothWrapper]: isBothMode,
            [editorWrapperCss.editorModeLexicalWrapper]: isLexicalMode,
          })}>
            {(isDraftjsMode || isBothMode) && <>
              {
                editMode ?
                  <ResizableButton>
                    <DraftjsButton/>
                  </ResizableButton>
                  : <DraftjsButton/>
              }
            </>}

            {(isLexicalMode || isBothMode) &&
            <div className={editorWrapperCss.lexicalEditor}>
              {
                editMode ?
                  <ResizableButton>
                    <LexicalButton/>
                  </ResizableButton>
                  : <LexicalButton/>
              }
            </div>}
          </div>
        </ResizeProvider>
      </ButtonProvider>
      {
        editMode &&
          <>
            <UndoPortal
              undo={undoHook.undo}
              redo={undoHook.redo}
              isRedoDisabled={undoHook.isRedoDisabled}
              isUndoDisabled={undoHook.isUndoDisabled}
            />
            <Toolbar
              editorProps={editorProps}
              editorSetters={editorSettersWithUndo}
              styles={styles}
              stylesSetters={stylesSettersWithUndo}
              projectType={projectType}
              toggleScriptStyle={undoHook.undoStackMiddleware(editorSetters.scriptStyle)}
              returnFocusToEditor={returnFocusToEditor}
              cellHeight={cellHeight}
              maxCellHeight={maxCellHeight}
              minCellHeight={minCellHeight}
              toggleCellHeight={undoHook.undoStackMiddleware(toggleRowHeight, 'cellHeight') as (newHeight: number) => number}
              cellWidth={cellWidth}
              toggleColumnWidth={undoHook.undoStackMiddleware(toggleColumnWidth, 'cellWidth') as (newWidth: number) => number}
              link={link}
              toggleLink={undoHook.undoStackMiddleware(toggleLink, 'link')}
              layoutId={layoutId}
              disableCellWidthEditing={disableCellWidthEditing}
              height={getResizeComponentHeight()}
              width={getResizeComponentWidth()}
              maxWidth={getMaxWidth()}
              isIncreasingWidthDisabled={isIncreasingWidthDisabled}
              toggleInlineStyle={undoHook.undoStackMiddleware(editorSetters.inlineStyle)}
              toggleAutoFitContent={undoHook.undoStackMiddleware(toggleAutoFitContent, 'isAutoFitContent')}
              isAutoFitContent={isAutoFitContent}
            />
          </>
      }
    </AccessibleDiv>
  );
};

export default CallToAction;
